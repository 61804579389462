import "../../resources/less/Admin/Login/AdminLoginPopup.less";
import "../../resources/less/Admin/Login/AdminLoginUserTypePopup.less";
import "../../resources/less/Admin/Login/TroubLoginPopup.less";
import JQuery from "jquery";
import NanoPopup from "../components/Popups/NanoPopup";
import NanomonxForm from "../components/Forms/NanomonxForm";

export default class LoginPopup {
  private readonly loginPopupContainer: JQuery<HTMLFormElement>;
  private readonly loginForm: JQuery<HTMLFormElement>;
  private readonly openLoginPopupBtn: JQuery<HTMLAnchorElement>;
  private readonly createAccountBtn: JQuery<HTMLAnchorElement>;
  private readonly backLoginPopupBtn: JQuery<HTMLAnchorElement>;
  private readonly loginFormSubmitButton: JQuery<HTMLButtonElement>;
  private readonly familyPopupUserType: JQuery<HTMLButtonElement>;
  private readonly familySelectionMsg: JQuery<HTMLButtonElement>;

  private loginFormPopup: NanoPopup;
  private userTypePopup: NanoPopup;

  constructor() {
    this.loginPopupContainer = $(".login-form-popup");
    this.loginForm = $("form[name='form-signin-popup']");
    this.loginFormSubmitButton = $(".submit-login-button");
    this.openLoginPopupBtn = $(".open-login-popup");
    this.createAccountBtn = $(".js-create-account-link");
    this.backLoginPopupBtn = $(".js-back-login-link");
    this.familyPopupUserType = $("#family-popup-user-types");
    this.familySelectionMsg = $(".family-selection-message");

    const loginPopupContext =
      this.loginPopupContainer.attr("data-popup-context");
    const url = new URL(window.location.href);

    // login popup
    this.loginFormPopup = new NanoPopup({
      target: ".nanopopup.login-form-popup",
    });

    // open login form popup
    this.openLoginPopupBtn.on("click", () => {
      this.loginForm.trigger("reset");
      this.loginFormPopup.Show();
    });

    // submit login form
    this.loginFormSubmitButton.on("click", () => {
      this.loginForm.trigger("submit");
    });

    // user types popup
    this.userTypePopup = new NanoPopup({
      target: ".nanopopup.login-user-type-popup",
    });

    // open user type popup
    this.createAccountBtn.on("click", () => {
      this.loginFormPopup.Hide();
      this.userTypePopup.Show();
    });

    // Back to login
    this.backLoginPopupBtn.on("click", () => {
      this.userTypePopup.Hide();
      this.loginFormPopup.Show();
      this.loginForm.trigger("reset");
    });

    if (
      this.loginForm.find(".invalid-credential").length > 0 ||
      this.loginForm.data("open-on-load") ||
      url.searchParams.get("open-login-popup")
    ) {
      this.loginFormPopup.Show();
    }

    // Admin Login Popup specific events
    if (loginPopupContext === "admin") {
      new NanomonxForm({
        elementName: "form-signin-popup",
        submitClass: ".admin-login-submit-btn",
        withValidation: true,
      });
    }

    // Family Login Popup specific events
    if (loginPopupContext === "family") {
      new NanomonxForm({
        elementName: "form-signin-popup",
        submitClass: ".family-login-submit-btn",
        withValidation: true,
        allowMultiSubmitBtn: true,
      });

      $("a[data-target-msg]").on("click", (ev) => {
        const targetMsg = $(ev.currentTarget).attr("data-target-msg");
        this.familyPopupUserType.addClass("hide");
        $("#" + targetMsg).removeClass("hide");
      });
      $(".cancel-btn, .close-container").on("click", () => {
        this.familySelectionMsg.addClass("hide");
        this.familyPopupUserType.removeClass("hide");
      });
    }
  }
}
